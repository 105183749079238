import * as React from 'react';
import { PropTypes } from 'prop-types';
import { Navigate } from 'react-router-dom';
import { PAGE_PATHS } from '../constants/page-paths';

export const PublicRoute = ({
  children,
  isUserLoggedIn = undefined,
  redirectTo = PAGE_PATHS.DASHBOARD,
}) => {
  if (isUserLoggedIn === undefined) return null;
  if (isUserLoggedIn === true) return <Navigate to={redirectTo} />;
  return children;
};

PublicRoute.propTypes = {
  children: PropTypes.node,
  isUserLoggedIn: PropTypes.bool,
  redirectTo: PropTypes.string,
};
