import { SelectedImage } from './avatar-image-links';

const avatarsPath = '/assets/images/avatars';

export const avatarOptions = [
  {
    id: 'whale',
    label: 'Whale',
    imageUrl: `${avatarsPath}/whale/whale-1.svg`,
    selectedUrl: SelectedImage,
    imageAlt: '',
    animatedUrl: `${avatarsPath}/whale/whale@2x-25fps.gif`,
    phone: `${avatarsPath}/whale/whale-phone.svg`,
    flag: `${avatarsPath}/whale/whale-flag.svg`,
    actions: [
      { id: 1, icon: `${avatarsPath}/whale/whale-plantmeal.svg` },
      { id: 2, icon: `${avatarsPath}/whale/whale-bicycle.svg` },
      { id: 3, icon: `${avatarsPath}/whale/whale-plastic.svg` },
      { id: 5, icon: `${avatarsPath}/whale/whale-tree.svg` },
      { id: 11, icon: `${avatarsPath}/whale/whale-avoidffashion.svg` },
      { id: 12, icon: `${avatarsPath}/whale/whale-coldwater.svg` },
    ],
  },
  {
    id: 'otter',
    label: 'Otter',
    imageUrl: `${avatarsPath}/otter/otter-1.svg`,
    selectedUrl: SelectedImage,
    imageAlt: '',
    animatedUrl: `${avatarsPath}/otter/otter@2x-25fps.gif`,
    phone: `${avatarsPath}/otter/otter-phone.svg`,
    star: `${avatarsPath}/otter/otter-star.svg`,
    flag: `${avatarsPath}/otter/otter-flag.svg`,
    actions: [
      { id: 1, icon: `${avatarsPath}/otter/otter-plantmeal.svg` },
      { id: 2, icon: `${avatarsPath}/otter/otter-bicycle.svg` },
      { id: 3, icon: `${avatarsPath}/otter/otter-plastic.svg` },
      { id: 5, icon: `${avatarsPath}/otter/otter-tree.svg` },
      { id: 11, icon: `${avatarsPath}/otter/otter-avoidffashion.svg` },
      { id: 12, icon: `${avatarsPath}/otter/otter-coldwater.svg` },
    ],
  },
  {
    id: 'starfish',
    label: 'Starfish',
    imageUrl: `${avatarsPath}/starfish/starfish-1.svg`,
    selectedUrl: SelectedImage,
    imageAlt: '',
    animatedUrl: `${avatarsPath}/starfish/star@2x-25fps.gif`,
    phone: `${avatarsPath}/starfish/starfish-phone.svg`,
    star: `${avatarsPath}/starfish/starfish-star.svg`,
    flag: `${avatarsPath}/starfish/starfish-flag.svg`,
    actions: [
      { id: 1, icon: `${avatarsPath}/starfish/starfish-plantmeal.svg` },
      { id: 2, icon: `${avatarsPath}/starfish/starfish-bicycle.svg` },
      { id: 3, icon: `${avatarsPath}/starfish/starfish-plastic.svg` },
      { id: 5, icon: `${avatarsPath}/starfish/starfish-tree.svg` },
      { id: 11, icon: `${avatarsPath}/starfish/starfish-avoidffashion.svg` },
      { id: 12, icon: `${avatarsPath}/starfish/starfish-coldwater.svg` },
    ],
  },
  {
    id: 'walrus',
    label: 'Walrus',
    imageUrl: `${avatarsPath}/walrus/walrus-1.svg`,
    selectedUrl: SelectedImage,
    imageAlt: '',
    animatedUrl: `${avatarsPath}/walrus/walrus@2x-25fps.gif`,
    phone: `${avatarsPath}/walrus/walrus-phone.svg`,
    star: `${avatarsPath}/walrus/walrus-star.svg`,
    flag: `${avatarsPath}/walrus/walrus-flag.svg`,
    actions: [
      { id: 1, icon: `${avatarsPath}/walrus/walrus-plantmeal.png` },
      { id: 2, icon: `${avatarsPath}/walrus/walrus-bicycle.svg` },
      { id: 3, icon: `${avatarsPath}/walrus/walrus-plastic.svg` },
      { id: 5, icon: `${avatarsPath}/walrus/walrus-tree.svg` },
      { id: 11, icon: `${avatarsPath}/walrus/walrus-avoidffashion.svg` },
      { id: 12, icon: `${avatarsPath}/walrus/walrus-coldwater.svg` },
    ],
  },
];
