/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://qbu2m2okmjchlenfac6op7zes4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-n7dchpghm5eshbwvb4zzf2p6jy",
    "aws_cognito_identity_pool_id": "us-east-1:8f3cac8e-2568-499a-8221-11aed2a97c83",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9HoW1VTDf",
    "aws_user_pools_web_client_id": "2p8kkg3oopvvqa418l5tcbdj11",
    "oauth": {
        "domain": "commit2act-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://app.commit2act.org/",
        "redirectSignOut": "http://localhost:3000/,https://app.commit2act.org/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "FACEBOOK"
    ],
    "aws_cognito_signup_attributes": [
        "NAME",
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "commit2act-storage14231-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
