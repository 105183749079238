const SIGNUP_FLOW_TYPE = {
  SIMPLE: 'simple',
  ONBOARD: 'onboard',
};

export const getSignupFlowType = () =>
  process.env.REACT_APP_SIGNUP_FLOW || SIGNUP_FLOW_TYPE.SIMPLE;

export const isSimpleSignupFlow = () =>
  getSignupFlowType() === SIGNUP_FLOW_TYPE.SIMPLE;
