import { useCallback, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { getAllActions } from '../graphql/queries';
import { getSimpleActionFromResponse } from '../utils/get-simple-action-from-response';

export const useGetAllActions = () => {
  const [allActions, setAllActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAllActions = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await API.graphql({
        query: getAllActions,
      });
      const actions = await response?.data?.getAllActions;
      setAllActions(actions.map(getSimpleActionFromResponse));
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (loading || error !== null) return;

    if (allActions.length === 0) {
      fetchAllActions();
    }
  }, [loading, error, allActions, fetchAllActions]);

  return {
    allActions,
    availableActions: allActions.filter((action) => !action?.hidden),
    loading,
    error,
  };
};
