import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { getActionItemsForAction } from '../graphql/queries';
import { getSimpleActionItemFromResponse } from '../utils/get-simple-action-item-from-response';

export const useGetItemsForAction = () => {
  const params = useParams();
  const paramsActionId = params?.actionId || null;

  const [actionId, setActionId] = useState(null);
  const [actionItems, setActionItems] = useState([]);
  const [hasNoItems, setHasNoItems] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchItemsForAction = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await API.graphql({
        query: getActionItemsForAction,
        variables: { action_id: paramsActionId },
      });
      const items = await response?.data?.getActionItemsForAction;
      setActionId(paramsActionId);
      setHasNoItems(items.length === 0);
      setActionItems(items.map(getSimpleActionItemFromResponse));
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }, [paramsActionId]);

  useEffect(() => {
    if (!paramsActionId || loading || error !== null) return;

    if (!hasNoItems && actionItems.length === 0) {
      fetchItemsForAction();
    }
  }, [
    paramsActionId,
    loading,
    error,
    hasNoItems,
    actionItems,
    fetchItemsForAction,
  ]);

  useEffect(() => {
    if (paramsActionId && paramsActionId !== actionId) {
      setHasNoItems(false);
      setActionItems([]);
    }
  }, [paramsActionId, actionId]);

  return {
    actionItems,
    hasNoItems,
    loading,
    error,
  };
};
