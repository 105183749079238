import * as React from 'react';
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { parseISO } from 'date-fns';
import { Box, CircularProgress, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useGetItemsForAction } from '../../../hooks/use-get-items-for-action';
import { useGetSingleAction } from '../../../hooks/use-get-single-action';
import useTranslation from '../../../components/customHooks/translations';
import { AddActionTextField } from '../../../components/AddActionTextField';
import ImageValidationPanel from '../../../components/logAction/ImageValidationPanel';
import { Button } from '../../../components/Button/Button';
import { LogActionContainer } from '../../../views/LogActionContainer/LogActionContainer';
import { PAGE_PATHS } from '../../../constants/page-paths';
import { getActionFormattedDate } from '../../../utils/get-action-formatted-date';
import { useCreateUserAction } from '../../../hooks/use-create-submitted-action';
import { getLogActionPath } from '../../../constants/log-action-paths';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import theme from '../../../themes';
import { useAvatar } from '../../../hooks/use-get-avatar.hook';

export const EnterDetails = () => {
  const navigate = useNavigate();
  const { actionId } = useParams();
  const translation = useTranslation();
  const { logActionPage, logActionStep2, chooseDate } = useTranslation();
  const {
    actionItems,
    loading: itemsLoading,
    hasNoItems,
  } = useGetItemsForAction();
  const { action, actionStyles } = useGetSingleAction();
  const { createUserAction, loading } = useCreateUserAction();

  const { userActionValues, setUserActionValues } = useOutletContext();
  const { avatar, isLoading } = useAvatar();

  const handleDateChange = (selectedDate) => {
    setUserActionValues({
      ...userActionValues,
      date: getActionFormattedDate(selectedDate),
    });
  };

  const handleItemChange = (event) => {
    const { id, value } = event.target;

    const updateItemValues = userActionValues?.actionItems.map((item) => {
      if (item.id !== id) return item;
      const numberValue = value.replace(/\D/g, '');
      return { id, value: numberValue };
    });

    setUserActionValues({ ...userActionValues, actionItems: updateItemValues });
  };

  const handleImageChange = (image) => {
    setUserActionValues({ ...userActionValues, image });
  };

  const onSubmitForm = async (event) => {
    event.preventDefault();
    createUserAction(() => {
      navigate(getLogActionPath('CREATE_ACTION', actionId));
    });
  };

  // On initial render when actionItems are available, update userActionValues
  // to include a blank entry for each actionItem id
  React.useEffect(() => {
    if (actionItems.length > 0 && userActionValues?.actionItems.length === 0) {
      const initialItemValues = actionItems.map((item) => ({
        id: item.id,
        value: '',
      }));

      setUserActionValues({
        ...userActionValues,
        actionItems: initialItemValues,
      });
    }
  }, [actionItems, userActionValues, setUserActionValues]);

  // On initial render, update userActionValues to display today's date
  React.useLayoutEffect(() => {
    if (!userActionValues?.date) {
      setUserActionValues({
        ...userActionValues,
        date: getActionFormattedDate(),
      });
    }
  });

  return (
    <LogActionContainer
      step={1}
      buttons={
        <>
          <Button
            component={Link}
            to={PAGE_PATHS.LOG_ACTION}
            disabled={loading || itemsLoading}
            variant="outlined"
            sx={{
              border: '1px solid white',
              [theme.breakpoints.down('sm')]: { order: '2' },
            }}
            startIcon={<ArrowBackIosIcon />}
          >
            {translation.back}
          </Button>
          <Button
            disabled={loading || itemsLoading}
            variant="contained"
            form="form-EnterDetails"
            type="submit"
          >
            {translation.next}
          </Button>
        </>
      }
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          alignItems: 'center',
          gap: '3rem',
          '& > figure': {
            flex: '0 0',
            '& > figcaption': {
              backgroundColor: 'rgba(0,0,0,0.5) !important',
              padding: '0.1em 0.35em',
              borderRadius: '0.2em',
            },
          },
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
            },
          }}
        >
          <Box component="form" id="form-EnterDetails" onSubmit={onSubmitForm}>
            <Box
              sx={{
                [theme.breakpoints.down('md')]: {
                  textAlign: 'center',
                },
              }}
            >
              <Typography variant="h2" component="p">
                {action?.name}
              </Typography>
              <Typography variant="h1" color={actionStyles}>
                {logActionStep2}
              </Typography>
            </Box>
            {itemsLoading && (
              <CircularProgress aria-label={logActionPage.loadingActionItems} />
            )}
            {!itemsLoading && !hasNoItems && actionItems.length === 0 ? (
              <Typography variant="p" component="p">
                {logActionPage.noActionItemsAvailable}
              </Typography>
            ) : null}
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={chooseDate}
                  value={parseISO(userActionValues?.date)}
                  onChange={handleDateChange}
                  renderInput={(inputProps) => (
                    <AddActionTextField
                      {...inputProps}
                      actionStyle={actionStyles}
                      disabled={loading}
                    />
                  )}
                  sx={{
                    '& .MuiTypography-root': { color: 'white' },
                  }}
                />
              </LocalizationProvider>
              {userActionValues?.actionItems.length > 0 &&
                actionItems.map((item) => (
                  <AddActionTextField
                    key={item.id}
                    id={item.id}
                    label={item.name}
                    helperText={item.description}
                    value={
                      userActionValues?.actionItems.find(
                        ({ id }) => id === item.id
                      ).value
                    }
                    onChange={handleItemChange}
                    actionStyle={actionStyles}
                    disabled={loading}
                  />
                ))}
              <ImageValidationPanel
                selectedImage={userActionValues?.image}
                setSelectedImage={handleImageChange}
                actionStyle={actionStyles}
                disabled={loading}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ [theme.breakpoints.down('md')]: { margin: '0 auto' } }}>
          {!isLoading && avatar && (
            <img src={avatar.phone || avatar.imageUrl} alt="" />
          )}
        </Box>
      </Box>
    </LogActionContainer>
  );
};
